body{
    background-color: #fafafa;
}

html,
body,
#root {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    
}

// Temp
.twitterContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% ;
}

// Temp
.twitter-timeline{ 
    max-width: 500px !important;
    width: 100%;
}


// Temp
.redditPosts{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:15px;
}

* {
    margin: 0px;
    padding: 0px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.page_with_menu_bar {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    flex: 1;
}

.container_error{
    display: flex;
    flex-direction:column;
    flex: 1;
    max-width: 500px;
    justify-content: center;
    align-items: flex-start;
    background-color:#f0f0f0;
    border-radius:10px;
    padding:10px;
}


#button_download_data_table {
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 3px;
    background-color: orange;
}

#button_download_data_table:hover {
    cursor: pointer
}

#popper_ego {
    z-index: 99;
}

#right_menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    #btn_logout {
        background-color: orange;
        color: #000000;
    }
}

#left_menu {
    .hamburgerProjects {
        display: flex;
        flex-direction: row;
    }

    .hamburgerProjects:hover {
        background-color: #4956632e;
        cursor: pointer;
    }

    .selectedProject {
        background-color: #adb5bd2e;
    }
}

#popper_ego {
    z-index: 99;
}



#DashboardProjects{
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center ;
    #page_content{
        max-width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        #no_access{
            display: flex;
            flex-direction: column;
            align-items: center;
            .btn-orange{
                background-color: orange;
                color: #000000;
                max-width: 200px;
            }
        }
    }
}

#HubPage {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;

    #page_container {
        height: calc(100% - 70px);
        width: 100%;
        display: flex;
        flex-direction: row;
        flex: 1;

        #container_left_bar {
            width: 100%;
            display: flex;
            background-color: #F5F5F5;
            flex: 1.5;
            order: 1;
            overflow: auto;

            #elements {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                padding: 10px;
                gap: 5px;
            }

            .AccordionDetails {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .active_disactive_filters {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }

        #container_right_bar {
            background-color: #F5F5F5;
            display: flex;
            flex: 1.5;
            order: 3;
            overflow: auto;

            #elements {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                padding: 10px;
                gap: 5px;
            }

            #download_section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5px;
            }

            .dropdownGraph {
                width: 100%;
            }
        }

        #container_graph {
            width: 100%;
            display: flex;
            position: relative;
            flex: 7;
            order: 2;

            #tooltip {
                position: absolute;
                background-color: orange;
                padding: 10px;
                border-radius: 3px;
                top: 20px;
                left: 20px;
            }
        }

        #container_toolpage {
            display: none;
            background-color: #F5F5F5;
            flex: 8.5;
            order: 1;

            #elements {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                padding: 50px;
            }

            #tools_table_container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 10px;
                margin-bottom: 10px;
            }
        }
    }
}

#InputPage {
    #testoCompleto {
        background-color: #E9ECEF;
    }
}

#RiassuntoPage {
    #testoRiassunto {
        background-color: #E9ECEF;
    }
}

#AnalisiPage {

    #div-soggetti-apicali-2,
    #div-soggetti-apicali-3 {
        margin-top: 30px;

    }
}

#TerminiSensibiliPage {
    .highlight {
        background-color: orange;
    }
}

#TwitterPage {
    #twitter_box {
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
    }

    #list_items {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    #new_query {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    #query_box {
        display: flex;
        flex-direction: column;
        gap: 5px
    }


    .list_element {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        max-width: 300px;
    }

    #table_user_info {
        margin-top: 30px;
    }
}

#Input2Page {
    #main_container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin: 20px;
    }

    #switch {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }
}

#SimulationPage {
    display: flex;
    flex-direction: column;
    flex:1;
    width: 100%;
    height: 100%;
    #simulaltion_content{
        height: calc(100% - 70px);
        width: 100%;
        display: flex;
        flex-direction: row;
        flex: 1;
        #toolbar-simulation{
            width: 100%;
            display: flex;
            flex-direction: column;
            flex:2;
            padding: 10px;
            overflow: auto;
        }
        #center_section_graph{
            display: flex;
            flex: 5;
        }
        #response_graphs {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex: 3;
            padding: 10px;
            overflow: auto;
            #response_graphs_1 {
                height: 40%;
            }
        
            #response_graphs_2 {
                height: 40%;
            }
        
            #diffusione_network {
                display: flex;
                flex-flow: column;
            }
        }
    }
}

#LoginRegisterPages {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: #6a11cb;
    background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));
    background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));

    #form_input {
        background-color: white;
        display: flex;
        flex-direction: column;
        max-width: 500px;
        padding: 20px;
        border-radius: 10px;
        width: 100%;
        gap: 10px;
    }

    #divider {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        gap: 5px;
        width: 100%;
    }

    @media screen and (min-height: 770px) {
        #login_page {
            height: 100%;
        }
    }

    @media screen and (min-height: 930px) {
        #register_page {
            height: 100%;
        }
    }
}

#MenuBar {
    #menu-bar {
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 1rem;
        align-items: center;
    }

    .nav-link {
        color: #FFF;
        font-size: large;
        font-weight: 400;
    }
}

#DataVisualizationPage {
    #main_content{
        display: flex;
        flex-direction: row;
        padding: 10px;
        background: #f1f1f1;
        #toolbar {
            display: flex;
            background-color: #0c0c0c38;
            padding: 10px;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            overflow: auto;
            border-radius: 5px;
            margin-top: 10px;
            margin-bottom: 10px;
            flex: 1
        }
    
        .grid-layout {
            display: flex;
            width: 100%;
            flex: 5;
        }
    
        .box {
            background-color: white;
            border-radius: 5px;
            padding: 5px;
            overflow: hidden;
        }
    
        .accordionFlex {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;
        }
    
        .block_accordion_graph {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 7px;
        }
    
        .table_data {
            width: 100%;
            height: 100%;
            overflow: auto;
            text-align: center;
        }
    
        .chart_container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
        }
    
        .chart_container {
            position: relative;
        }
    
        .icon_download {
            position: absolute;
            top: 0px;
            right: 5px;
        }
    
        .icon_delete {
            position: absolute;
            top: 0px;
            right: 35px;
        }
    
        .pagination {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    
        tbody tr:nth-child(odd) {
            background-color: aliceblue;
        }
    
        .header_input {
            border-radius: 3px;
            border: unset;
        }
    
        .type_column {
            display: flex;
            flex-direction: row;
        }
    
        #icon_change_type {
            padding: 0;
        }
    
        .header_input {
            border-style: hidden;
        }
    
        .header_input:focus {
            outline: none;
        }
    }
}

#TreDPage{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
    #page_container{
        height: calc(100% - 70px);
        width: 100%;
        display: flex;
        flex-direction: row;
        flex: 1;
        #left_bar{
            display: flex;
            flex-direction: column;
            flex:1.5;
            margin-top: 10px;
            gap:10px;
            padding:10px;
        }
    
        #treD_graph{
            display: flex;
            flex:8.5
        }
    }
}

#vrPAGE{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    #page_content{
        height: calc(100% - 70px);
        width: 100%;
        display: flex;
        flex: 1;
    }
}